

























































import { computed, onBeforeMount, ref } from "@vue/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import { sharedRef } from "@konfetti-core/core";
import Pagination from "vue-pagination-2";
import { KftHeading } from "@konfetti-ui/vue";
import { nextTick } from "@nuxtjs/composition-api";
import {
  useSupplier,
  supplierGetters,
  useProduct,
} from "@konfetti/composables";
import { KftPagination, KftProductShowcase, KftSimpleCard } from "~/components";
import { useAlgoliaEventApi } from "~/composables";
import {
  fnGetEventListIndexName,
  getSupplierSubdomain,
  parseEventDescriptionToAlgoliaEventItem,
} from "~/helpers";

export default {
  name: "SupplierHomepage",
  components: {
    LazyHydrate,
    KftPagination,
    KftSimpleCard,
    KftHeading,
    KftProductShowcase,
    Pagination,
  },
  layout() {
    return "supplier";
  },
  setup(props, { root: { context, $options, $i18n, $scrollToTop } }) {
    const supplierScope = sharedRef(null, "supplier-scope");

    const {
      fnInit: fnInitAlgoliaEventApi,
      isLoaded: isAlgoliaLoaded,
      loading,
    } = useAlgoliaEventApi("supplier-instance");
    const { supplier } = useSupplier("supplier-layout");

    const {
      /* Variables */
      supplierEvents,

      /* Methods */
      loadSupplierEvents,
    } = useProduct("supplier-layout");

    const paginationOptions = ref({
      page: 1,
      perPage: 10,
      nbHits: 0,
      isVisible: false,
    });

    const parsedSupplierEvents = computed(() => {
      return supplierEvents.value?.data.map((el) =>
        parseEventDescriptionToAlgoliaEventItem(el),
      );
    });

    const promoPosition = computed(() =>
      Math.min(2, (supplierEvents.value?.data.length || 0) - 1),
    );

    const shouldEnableGiftcard = computed(() =>
      supplierGetters.getHasGiftcardEmbedded(supplier.value?.data),
    );

    const fetchParseSupplierEvents = async () => {
      if (supplierEvents.value.data.length === 0) {
        await loadSupplierEvents(
          supplierGetters.getId(supplier.value?.data),
          true,
        );
      }
    };

    const paginate = async (page) => {
      paginationOptions.value = page;

      fetchParseSupplierEvents();

      await nextTick();
      await $scrollToTop();
    };

    onBeforeMount(async () => {
      if (!isAlgoliaLoaded.value) {
        await fnInitAlgoliaEventApi(fnGetEventListIndexName($i18n.locale));
      }

      supplierScope.value = getSupplierSubdomain(
        context?.nuxtState || context?.ssrContext?.nuxt,
        $options?.config,
      );

      fetchParseSupplierEvents();
    });

    return {
      paginationOptions,
      parsedSupplierEvents,
      loading,
      paginate,
      KftPagination,
      promoPosition,
      shouldEnableGiftcard,
    };
  },
};
